import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "../../redux/actions/user";
import { DataGrid } from "@material-ui/data-grid";
import { AiOutlineDelete } from "react-icons/ai";
import { Button } from "@material-ui/core";
import styles from "../../styles/styles";
import { RxCross1 } from "react-icons/rx";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import { AiOutlinePlus ,AiOutlineEye} from 'react-icons/ai';
import { Link, useNavigate } from "react-router-dom";

const AllUsers = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.user);
  const [open, setOpen] = useState(false);
  const [userId, setUserId] = useState("");
  const [searchTerm, setSearchTerm] = useState('');
  const [item, setitem] = useState([]);

  useEffect(() => {
  
    dispatch(getAllUsers());
  }, [dispatch]);

  const handleDelete = async (id) => {
    await axios
      .delete(`${server}/user/delete-user/${id}`, { withCredentials: true })
      .then((res) => {
        toast.success(res.data.message);
      });

    dispatch(getAllUsers());
  };

  const columns = [
    { field: "id", headerName: "User ID", minWidth: 150, flex: 0.7 },

    {
      field: "name",
      headerName: "Nom & Prenom",
      minWidth: 130,
      flex: 0.7,
    },
    {
      field: "fonction",
      headerName: "Fonction",
      type: "text",
      minWidth: 130,
      flex: 0.7,
    },
    {
      field: "agence",
      headerName: "Agence",
      type: "text",
      minWidth: 130,
      flex: 0.7,
    },

    {
      field: "Dr",
      headerName: "DR",
      type: "text",
      minWidth: 130,
      flex: 0.8,
    },

    {
      field: " ",
      flex: 1,
      minWidth: 150,
      headerName: "Action",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
          <Link to={`/user-create/${params.id}`}>
           <Button>
                <AiOutlineEye size={20} />
              </Button>
              </Link>
            <Button onClick={() => setUserId(params.id) || setOpen(true)}>
              <AiOutlineDelete size={20} />
            </Button>
          </>
        );
      },
    },
    
  ];

  const row = [];
  users &&
    users.forEach((item) => {
      row.push({
        id: item.Matricule,
        name: item.label,
        fonction: item.Fonction,
        agence: item.Agence,
        Dr: item.DR
        // joinedAt: item.createdAt.slice(0, 10),
      });
    });


  const filter = () => {
    if (searchTerm !== "") {
      const filtered = row.filter((item) => {
        return Object.values(item).some((value) =>
          String(value).toLowerCase().includes(searchTerm.toLowerCase())
        )
      })
      setitem(filtered)
    } else {
      setitem(row)
    }
  }


  useEffect(() => {
    filter()
  }, [searchTerm,row])


  return (
    <div className="w-full flex justify-center pt-5">
      <div className="w-[97%]">
        <div className="flex items-center justify-between pb-2">
          <h3 className="text-[22px] font-Poppins">Tous les agents</h3>
          <button className="bg-black text-white py-2 px-4 rounded flex items-center hover:bg-gray-800"
            onClick={() => navigate('/user-create')}
          >
            <AiOutlinePlus className="mr-2" />
            Ajouter
          </button>
        </div>
        <input
          type="text"
          placeholder="Rechercher..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="border border-gray-300 rounded px-2 py-1 mb-4 w-full"
        />
        <div className="w-full min-h-[45vh] bg-white rounded">
          <DataGrid
            rows={item}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            autoHeight
          />
        </div>
        {open && (
          <div className="w-full fixed top-0 left-0 z-[999] bg-[#00000039] flex items-center justify-center h-screen">
            <div className="w-[95%] 800px:w-[40%] min-h-[20vh] bg-white rounded shadow p-5">
              <div className="w-full flex justify-end cursor-pointer">
                <RxCross1 size={25} onClick={() => setOpen(false)} />
              </div>
              <h3 className="text-[25px] text-center py-5 font-Poppins text-[#000000cb]">
              Etes-vous sûr de vouloir supprimer cet utilisateur ?
              </h3>
              <div className="w-full flex items-center justify-center">
                <div
                  className={`${styles.button} text-white text-[18px] !h-[42px] mr-4`}
                  onClick={() => setOpen(false)}
                >
                  cancel
                </div>
                <div
                  className={`${styles.button} text-white text-[18px] !h-[42px] ml-4`}
                  onClick={() => setOpen(false) || handleDelete(userId)}
                >
                  confirm
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AllUsers;
