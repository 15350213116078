import React,{useEffect} from 'react'
import Header from "../components/Layout/Header";
import Hero from "../components/Route/Hero/Hero";
import BestDeals from "../components/Route/BestDeals/BestDeals";
import FeaturedProduct from "../components/Route/FeaturedProduct/FeaturedProduct";
import Events from "../components/Events/Events";
import Sponsored from "../components/Route/Sponsored";
import Footer from "../components/Layout/Footer";
import Cookies from 'js-cookie';
const HomePage = () => {

  // useEffect(() => {
  //   // Check if the token is expired on app load
  //   if (checkTokenExpiration()) {
  //     logout(); // If expired, logout the user
  //   }

  //   // Optionally, set an interval to check every 5 minutes or so
  //   const interval = setInterval(() => {
  //     if (checkTokenExpiration()) {
  //       logout(); // If expired, logout the user
  //     }
  //   }, 5 * 60 * 1000); // 5 minutes

  //   // Clean up the interval on component unmount
  //   return () => clearInterval(interval);
  // }, []);
  // const checkTokenExpiration = () => {
  //   const token =  Cookies.get('token');; // or use cookies if you're storing the token there
  //   if (!token) {
  //     return true; // If no token exists, consider it expired
  //   }
  
  //   const decodedToken = jwt_decode(token);
  //   const currentTime = Date.now() / 1000; // Current time in seconds
  
  //   // If the token has expired
  //   if (decodedToken.exp < currentTime) {
  //     return true; // Expired
  //   }
  
  //   return false; // Token is still valid
  // };
  
  // const logout = () => {
    
  //   // document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  //   // window.location.href = '/login'; // Redirect to login page
  // };
  return (
    <div>
        <Header activeHeading={1} />
        <Hero />
        
        <BestDeals />
       
       
        <Footer />
    </div>
  )
}

export default HomePage