import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@material-ui/data-grid";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import { Button } from "@material-ui/core";
import styles from "../../styles/styles";
import { RxCross1 } from "react-icons/rx";
import axios from "axios";
import { server, serverip } from "../../server";
import { toast } from "react-toastify";
import { getAllSellers } from "../../redux/actions/sellers";
import { Link } from "react-router-dom";
import { AiOutlinePlus } from 'react-icons/ai';
const AllSellers = () => {
  const dispatch = useDispatch();
  const { sellers } = useSelector((state) => state.seller);
  const [open, setOpen] = useState(false);
  const [userId, setUserId] = useState("");
  const [searchTerm, setSearchTerm] = useState('');
  const [item, setitem] = useState([]);
  useEffect(() => {
    
    dispatch(getAllSellers());
  }, [dispatch]);

  const handleDelete = async (id) => {
    await axios
      .delete(`${serverip}/delete-Agence/${id}`, { withCredentials: true })
      .then((res) => {
        toast.success(res.data.message);
      });

    dispatch(getAllSellers());
  };





  const columns = [
    { field: "id", headerName: "Code", minWidth: 150, flex: 0.7 },

    {
      field: "name",
      headerName: "name",
      minWidth: 130,
      flex: 0.7
    },
    {
      field: "DR",
      headerName: "DR",
      type: "text",
      minWidth: 130,
      flex: 0.7
    },




  ];

  const row = [];
  sellers &&
    sellers.forEach((item) => {
      row.push({
        id: item.code,
        name: item?.label,
        DR: item?.DR

      });

    });

  const filter = () => {
    if (searchTerm !== "") {
      const filtered = row.filter((item) => {
        return Object.values(item).some((value) =>
          String(value).toLowerCase().includes(searchTerm.toLowerCase())
        )
      })
      setitem(filtered)
    } else {
      setitem(row)
    }
  }


  useEffect(() => {
    filter()
  }, [searchTerm])

  return (
    <>
      <div className="w-[97%]">
        <div className="flex items-center justify-between pb-2">
          <h3 className="text-[22px] font-Poppins">Tous les agences</h3>
          <button className="bg-black text-white py-2 px-4 rounded flex items-center hover:bg-gray-800">
            <AiOutlinePlus className="mr-2" />
            Ajouter
          </button>
        </div>
        <input
          type="text"
          placeholder="Rechercher..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="border border-gray-300 rounded px-2 py-1 mb-4 w-full"
        />
        <div className="w-full mx-8 pt-1 mt-10 bg-white">
         { item && 
            <DataGrid
            rows={item}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            autoHeight
          />
          }
          
        </div>
      </div>
    </>

  );
};

export default AllSellers;
